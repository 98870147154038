<!-- This template is a wrapper for the Stripe Pricing Table. See https://stripe.com/docs/payments/checkout/pricing-table for more details -->
<template>
  <div>
    <stripe-pricing-table
      :pricing-table-id="pricingTableId"
      :publishable-key="publishableKey"
      :customer-email="customerEmail"
    />
  </div>
</template>

<script>
export default {
  props: {
    customerEmail: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pricingTableId: process.env.VUE_APP_STRIPE_PRICING_TABLE_ID,
      publishableKey: process.env.VUE_APP_STRIPE_API_KEY,
    }
  },
}
</script>
